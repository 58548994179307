import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				FitZone Gym Hakkında
			</title>
			<meta name={"description"} content={"FitZone, modern spor salonumuz ve sunduğumuz geniş hizmet yelpazesi hakkında bilgi edinin."} />
			<meta property={"og:title"} content={"FitZone Gym Hakkında"} />
			<meta property={"og:description"} content={"FitZone, modern spor salonumuz ve sunduğumuz geniş hizmet yelpazesi hakkında bilgi edinin."} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://varuntarest.com/img/43625757.png"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Misyonumuz ve Vizyonumuz
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				FitZone'da misyonumuz, istisnai fitness merkezleri ve hizmetleri aracılığıyla insanlara daha sağlıklı ve aktif bir yaşam sürme imkanı sunmaktır. Vizyonumuz, herkesin fitness yolculuğunda desteklendiğini hissettiği misafirperver ve motive edici bir ortam yaratmaktır.
				<br />
				<br />
				Üyelerimize en iyi olanakları sunmayı, tam kapsamlı hizmetler, yüksek kaliteli ekipman ve uzman rehberlik sağlamayı hedefliyoruz. Amacımız, fitness hedeflerinize ulaşmanıza ve genel sağlığınızı iyileştirmenize yardımcı olmaktır.
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					display="inline-block"
					text-align="center"
					margin="20px 0px 0px 0px"
					type="link"
					text-decoration-line="initial"
					href="/contact"
					background="--color-orange"
				>
					Şimdi Kayıt Ol
				</Button>
			</Box>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://varuntarest.com/img/7.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://varuntarest.com/img/8.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://varuntarest.com/img/9.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Components.Testimonials>
			<Override slot="text" />
		</Components.Testimonials>
		<Components.Footer />
	</Theme>;
});